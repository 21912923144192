<template>
  <div>
    <b-container>
      <h1 class="mt-3">Stage 3</h1>
      <h3>Transcript Filter and Quality Control</h3>
      <p class="text-left">Step 1: Splice Junction (SJ) Filter<br/>
      Step 2: Transcript Start and End Site Analysis<br/>
      Step 3: Merge Redundant Transcripts in the Filtered RTD</p>
      
      <p class="m-0 font-weight-bold">Select the folder which contains the relevant data for filtering:</p>
      <b-row><b-col>
        <b-alert show variant="danger" v-if=errorMsg>{{ errorMsg }}
          <div v-if=(errorShowRefresh)>Please refresh this page and try again, or contact an administrator if you continue to see this error.</div>
        </b-alert>
      </b-col></b-row>
    
      <b-form @submit.prevent="submitJob">
        <folder-browser :folders="folders" :selectedFolder="selectedFolder"
          @select-folder="folderSelected">
        </folder-browser>

        <p class="m-0 text-left">Parameter Settings:
          <ul>
            <li>Splice junction error region: The read alignment error is checked up- and down-stream of the splice junctions with provided size of error region.</li>
            <li>TSS/TES test statistics cut-off: Threshold of TSS/TES enrichment test. Please select a proper value for different test types.</li>
            <li>TSS/TES test statistics type: Statistics types to report significance of enrichment agaist random guess. Options: “fdr” for BH adjustd p-value; “pval” for p-value and “prob” for probability differs to randon guess.</li>
            <li>Minimum read count: The minimum number of reads to support a TSS/TES.</li>
            <li>Bin size to count reads: Total reads within +/-bin of the TSS/TES are used as the read count of the site.</li>
            <li>TSS wobbling region: All TSSs locate within +/-region of significantly enriched site are high confident TSSs.</li>
            <li>TES wobbling region: All TESs locate within +/-region of significantly enriched site are high confident TESs.</li>
            <li>TSS Collapse Threshold: The amount of tolerance at the 5' end of the transcript for grouping reads to be collapsed.</li>
            <li>TES Collapse Threshold: The amount of tolerance for the 3' end of the transcript for grouping reads to be collapsed.</li>
          </ul>
        </p>

        <b-row class="input-row">
          <b-input-group>
            <b-input-group-prepend class="input-label">
              <b-input-group-text class="bg-white w-100">Splice Junction Error Region:</b-input-group-text>
            </b-input-group-prepend>
            <div class="input-box " v-b-tooltip.hover.html :title="SJErrorTip">
              <b-form-input id="SJErrorInput" v-model="SJError" type="number" min="0" class="bg-secondary"></b-form-input>
            </div>
          </b-input-group>
        </b-row>

        <b-row class="input-row">
          <b-input-group>
            <b-input-group-prepend class="input-label">
              <b-input-group-text class="bg-white w-100">TSS/TES Test Statistics Cut-Off:</b-input-group-text>
            </b-input-group-prepend>
            <div class="input-box " v-b-tooltip.hover.html :title="StatsCutOffTip">
              <b-form-input id="StatsCutOffInput" v-model="StatsCutOff" type="number" min="0" max="1" step="0.01" class="bg-secondary"></b-form-input>
            </div>
          </b-input-group>
        </b-row>

         <b-row class="input-row">
          <b-input-group>
            <b-input-group-prepend class="input-label">
              <b-input-group-text class="bg-white w-100">TSS/TES Test Statistics Type:</b-input-group-text>
            </b-input-group-prepend>
            <div class="input-box" v-b-tooltip.hover.html :title="StatsTypeTip">
              <b-form-select id="StatsTypeInput" v-model="StatsType" :options="options" class="bg-secondary"></b-form-select>
            </div>
          </b-input-group>
        </b-row>

        <b-row class="input-row">
          <b-input-group>
            <b-input-group-prepend class="input-label">
              <b-input-group-text class="bg-white w-100">Minimum Read Count:</b-input-group-text>
            </b-input-group-prepend>
            <div class="input-box " v-b-tooltip.hover.html :title="MinReadTip">
              <b-form-input id="MinReadInput" v-model="MinRead" type="number" min="0" class="bg-secondary"></b-form-input>
            </div>
          </b-input-group>
        </b-row>

        <b-row class="input-row">
          <b-input-group>
            <b-input-group-prepend class="input-label">
              <b-input-group-text class="bg-white w-100">Bin Size to Count Reads:</b-input-group-text>
            </b-input-group-prepend>
            <div class="input-box " v-b-tooltip.hover.html :title="BinSizeTip">
              <b-form-input id="BinSizeInput" v-model="BinSize" type="number" min="0" class="bg-secondary"></b-form-input>
            </div>
          </b-input-group>
        </b-row>

        <b-row class="input-row">
          <b-input-group>
            <b-input-group-prepend class="input-label">
              <b-input-group-text class="bg-white w-100">TSS Wobbling Region:</b-input-group-text>
            </b-input-group-prepend>
            <div class="input-box " v-b-tooltip.hover.html :title="TSSWobbleTip">
              <b-form-input id="TSSWobbleInput" v-model="TSSWobble" type="number" min="0" class="bg-secondary"></b-form-input>
            </div>
          </b-input-group>
        </b-row>

        <b-row class="input-row">
          <b-input-group>
            <b-input-group-prepend class="input-label">
              <b-input-group-text class="bg-white w-100">TES Wobbling Region:</b-input-group-text>
            </b-input-group-prepend>
            <div class="input-box " v-b-tooltip.hover.html :title="TESWobbleTip">
              <b-form-input id="TESWobbleInput" v-model="TESWobble" type="number" min="0" class="bg-secondary"></b-form-input>
            </div>
          </b-input-group>
        </b-row>

        <b-row class="input-row">
          <b-input-group>
            <b-input-group-prepend class="input-label">
              <b-input-group-text class="bg-white w-100">TSS Collapse Threshold:</b-input-group-text>
            </b-input-group-prepend>
            <div class="input-box " v-b-tooltip.hover.html :title="TSSCollapseTip">
              <b-form-input id="TSSCollapseInput" v-model="TSSCollapse" type="number" min="0" class="bg-secondary"></b-form-input>
            </div>
          </b-input-group>
        </b-row>

        <b-row class="input-row">
          <b-input-group>
            <b-input-group-prepend class="input-label">
              <b-input-group-text class="bg-white w-100">TES Collapse Threshold:</b-input-group-text>
            </b-input-group-prepend>
            <div class="input-box " v-b-tooltip.hover.html :title="TESCollapseTip">
              <b-form-input id="TESCollapseInput" v-model="TESCollapse" type="number" min="0" class="bg-secondary"></b-form-input>
            </div>
          </b-input-group>
        </b-row>

        <b-form-checkbox
          id="NameCheck"
          v-model="nameCheck"
          name="NameCheck"
        >
          Tick if you want to use a custom name for the results folder.
        </b-form-checkbox>

        <b-row v-if="nameCheck" class="input-row">
          <b-input-group>
            <b-input-group-prepend class="input-label">
              <b-input-group-text class="bg-white w-100">Custom folder name:</b-input-group-text>
            </b-input-group-prepend>
            <div class="input-box">
              <b-form-input id="nameInput" v-model="name" type="text" class="bg-secondary"></b-form-input>
            </div>
          </b-input-group>
        </b-row>

        <b-button type="submit" variant="primary" :disabled="selectedFolder.files.length === 0 || errorMsg != null">Submit</b-button>
      </b-form>

    </b-container>
  </div>
</template>

<script>
import FolderBrowser from '../components/FolderBrowser.vue'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: "assembly",
  components: {
    "folder-browser": FolderBrowser
  },
  data() {
    return {
      // Error message that we want to display (if any)
      errorMsg: null,
      // Should the UI show a 'please refresh' msg when showing the error
      errorShowRefresh: true,
      data: null,
      folders: null,
      selectedFolder: {
        name: null,
        files: []
      },
      SJError: 10,
      StatsCutOff: 0.01,
      options: [
        "fdr",
        "pval",
        "prob"
      ],
      StatsType: "fdr",
      MinRead: 2,
      BinSize: 5,
      TSSWobble: 50,
      TESWobble: 50,
      TSSCollapse: 50,
      TESCollapse: 50,

      showSJError: false,
      showStatsCutOff: false,
      showStatsType: false,
      showMinRead: false,
      showBinSize: false,
      showTSSWobble: false,
      showTESWobble: false,
      showTSSCollapse: false,
      showTESCollapse: false,

      SJErrorTip: "The read alignment error is checked up- and down-stream of the splice junctions with provided size of error region.",
      StatsCutOffTip: "Threshold of TSS/TES enrichment test. Please select a proper value for different test types.",
      StatsTypeTip: "Statistics types to report significance of enrichment agaist random guess. Options: “fdr” for BH adjustd p-value; “pval” for p-value and “prob” for probability differs to randon guess.",
      MinReadTip: "The minimum number of reads to support a TSS/TES.",
      BinSizeTip: "Total reads within +/-bin of the TSS/TES are used as the read count of the site.",
      TSSWobbleTip: "All TSSs locate within +/-region of significantly enriched site are high confident TSSs.",
      TESWobbleTip: "All TESs locate within +/-region of significantly enriched site are high confident TESs.",
      TSSCollapseTip: "The amount of tolerance at the 5' end of the transcript for grouping reads to be collapsed.",
      TESCollapseTip: "The amount of tolerance for the 3' end of the transcript for grouping reads to be collapsed.",

      nameCheck: false,
      name: ""
    }
  },

  computed: {
    ...mapGetters({token: "token"})
  },

  mounted() {
    axios.get("/folders?stage=lr3",
      {
        params: { token: this.token },
        headers: {"Content-Type": "application/json"}
      }).then(
        function(response) {
          this.folders = response.data;
          this.onLoad();
        }.bind(this)
      )
      .catch(error => {
        console.log(error)
        this.errorMsg = "Unable to retrieve a list of folders.";
      });
  },

  methods: {
    onLoad: function() {
      if(this.$route.params.resubmitData != null && this.$route.params.resubmitData != undefined)
      {
        this.folderID = this.$route.params.resubmitData.assemblyFolder;

        this.SJError = this.$route.params.resubmitData.SJError;
        this.StatsCutOff = this.$route.params.resubmitData.statsCutOff;
        this.StatsType = this.$route.params.resubmitData.statsType;
        this.MinRead = this.$route.params.resubmitData.minRead;
        this.BinSize = this.$route.params.resubmitData.binSize;
        this.TSSWobble = this.$route.params.resubmitData.TSSWobbling;
        this.TESWobble = this.$route.params.resubmitData.TESWobbling;
        this.TSSCollapse = this.$route.params.resubmitData.TSSCollapse;
        this.TESCollapse = this.$route.params.resubmitData.TESCollapse;

        if(this.folderID !== undefined && this.folderID !== null)
        {
          this.folders.forEach(folder => {
            if((folder.id.localeCompare(this.folderID) == 0))
            {
              this.folderSelected(folder);
            }
          });
        }
      }
    },

    folderSelected: function(folder) {
      this.selectedFolder = folder;
    },

    submitJob: function() {
      var custName = null;
      if(this.nameCheck) {
        custName = this.name;
      }

      axios.get("/longread/filter", {
        params: {
          token: this.token,
          assemblyFolderID: this.selectedFolder.id,
          SJError: this.SJError,
          StatsCutOff: this.StatsCutOff,
          StatsType: this.StatsType,
          MinRead: this.MinRead,
          BinSize: this.BinSize,
          TSSWobble: this.TSSWobble,
          TESWobble: this.TESWobble,
          TSSCollapse: this.TSSCollapse,
          TESCollapse: this.TESCollapse,
          name: custName
        },
        headers: {"Content-Type": "application/json"}
        }).then(
          function(response) {
            this.data = response.data;

            this.$router.push({ name: 'tracking', params: { folderID: response.data.folderID }})

          }.bind(this)
        )
        .catch(error => {
          console.log(error)
          this.errorMsg = "ERROR";
        });
    }
  }
}
</script>

<style scoped>
.error {
  color: #ff0000;
}
</style>